import * as Sentry from '@sentry/browser'

let options = {
    dsn: '',
    environment: ''
}

if (document.documentElement.dataset.sentry) {
    try {
        const settings = JSON.parse(document.documentElement.dataset.sentry)
        options = {
            ...options,
            ...settings
        }
    } catch (e) {}
}

if (document.documentElement.dataset.sentryDsn) {
    options.dsn = document.documentElement.dataset.sentryDsn
}

if (document.documentElement.dataset.sentryEnvironment) {
    options.environment = document.documentElement.dataset.sentryEnvironment
}

if (options.dsn) {
    const { tags = {}, ...config } = options
    tags.logger = 'js'

    Sentry.init(config)
    Sentry.configureScope(scope => {
        Object.entries(tags).forEach(entry => scope.setTag(...entry))
    })
}