import Emitter from '../../core/Emitter'
import EventBus from '../../core/EventBus'
import throttle from 'lodash/throttle'

export const STATES = {
    DISABLED: 'is-scrolling-disabled'
}

export class NativeScroll extends Emitter {
    constructor() {
        super()

        this.current = {
            x: 0,
            y: 0
        }

        this.window = {
            height: window.innerHeight
        }

        this.scrollElementBox = {} //cache getBoundingClientRect scrollovanyho elementu

        this.offset = 0
        this.disableScrollCounter = 0
        this.isAttached = false
        this.scrollToService = new ScrollToService

        EventBus.on('contentchange', this.handleResize)
        this.handleResize = throttle(this.handleResize, 50)
        window.addEventListener('resize', this.handleResize)
    }

    destroy() {
        window.removeEventListener('resize', this.handleResize)
        EventBus.off('contentchange', this.handleResize)
    }

    attach() {
        if (this.isAttached) {
            return
        }

        window.addEventListener('scroll', this.handleScroll, {passive:true})
        this.isAttached = true
    }

    detach() {
        if (!this.isAttached) {
            return
        }

        window.removeEventListener('scroll', this.handleScroll, {passive:true})
    }

    useNative() {
        this.attach()
        //pouziva se ve VirtualScroll
    }

    useVirtual() {
        console.error('Není naimportovaný virtual scroll v scroll/index.js')
        //pouziva se ve VirtualScroll
    }

    handleResize = () => {
        this.resize()
    }

    handleScroll = () => {
        this.current = this.getScroll()

        this.render()
    }

    resize() {
        this.emit('resize')

        this.window = {
            height: window.innerHeight
        }
    }

    render() {
        this.emit('scroll', this.current)
    }

    disableScroll() {
        this.disableScrollCounter += 1

        if (this.disableScrollCounter > 1) {
            return
        }

        this.offset = this.getScroll().y
        document.body.style.top = `${-this.offset}px`
        document.documentElement.classList.add(STATES.DISABLED)
    }

    enableScroll(force = false) {
        if (this.disableScrollCounter === 0) {
            return
        }

        if (force) {
            this.disableScrollCounter = 0
        } else {
            this.disableScrollCounter -= 1

            if (this.disableScrollCounter > 0) {
                return
            }
        }

        document.body.removeAttribute('style')
        document.documentElement.classList.remove(STATES.DISABLED)
        document.body.offsetWidth
        window.scrollTo(0, this.offset)
    }

    handleResize = () => {
        this.resize()
        this.emit('resize')
    }


    getScroll() {
        return {
            x: document.body.scrollLeft || document.documentElement.scrollLeft || 0,
            y: document.body.scrollTop || document.documentElement.scrollTop || 0
        }
    }

    getScrollOffset() {
        //mozno predefinovat podle projektu
        return 0
    }

    setPosition(x, y) {
        document.body.scrollLeft = x
        document.documentElement.scrollLeft = x
        document.body.scrollTop = y
        document.documentElement.scrollTop = y
    }

    scrollToElement(element, options) {
        options = {
            animate: true,
            mode: 'force',
            ...options
        }

        if (Array.isArray(element)) {
            this.scrollElementBox = element.map(el => el.getBoundingClientRect()).reduce((acc, rect) => {
                acc['top'] = rect.top > acc['top'] ? acc['top'] : rect.top
                acc['bottom'] = rect.bottom < acc['bottom'] ? acc['bottom'] : rect.bottom
                acc['height'] = acc['bottom'] - acc['top']
                return acc
            },{})
        } else {
            this.scrollElementBox = element.getBoundingClientRect()
        }

        if (options.mode === 'auto' && this.isElementInView(element)) {
            return
        }

        let offset = options.offset ? options.offset : this.getScrollOffset()
        if (options.mode === 'auto' || options.mode === 'force-center') {
            offset = offset + this.getOffsetToCenterElement(element)
        }

        if (options.animate) {
            this.scrollToService.scrollTo(this.getScroll().y + this.scrollElementBox.top - offset)
        } else {
            this.setPosition(0, this.getScroll().y + this.scrollElementBox.top - offset)
        }
    }

    isElementInView(element) {
        //mozna brat v potaz getScrollOffset?
        if (this.scrollElementBox.top < 0) {
            return false //moc vysoko
        }

        if (this.scrollElementBox.top > this.window.height * 3 / 5) {
            return false //moc nizko
        }

        return true
    }

    getOffsetToCenterElement(element) {
        const offset = this.getScrollOffset()
        const remaningSpace = Math.max(0,this.window.height - offset - this.scrollElementBox.height)
        return remaningSpace / 3
    }
}

class ScrollToService {
    constructor() {
        this.UP = -1
        this.DOWN = 1

        this.friction = 0.7
        this.acceleration = 0.04

        this.positionY = 100
        this.velocityY = 0
        this.targetPositionY = 400

        this.raf = null

        window.addEventListener('mousewheel', () => {
            if (this.raf) {
                cancelAnimationFrame(this.raf)
                this.raf = null
            }
        }, {passive: true})
    }

    getScrollTop() {
        return document.body.scrollTop || document.documentElement.scrollTop
    }

    animate() {
        const distance = this.getCurrentDistance()
        this.render()

        if (Math.abs(distance) > 0.1) {
            this.raf = requestAnimationFrame(() => this.animate())
        }
    }

    getCurrentDistance() {
        const distance = this.targetPositionY - this.positionY
        const attraction = distance * this.acceleration

        this.velocityY += attraction

        this.velocityY *= this.friction
        this.positionY += this.velocityY

        return distance
    }

    render() {
        window.scrollTo(0, this.positionY)
    }

    scrollTo(offset, callback) {
        this.positionY = this.getScrollTop()
        this.targetPositionY = offset
        this.velocityY = 0
        this.animate()
    }
}

export default new NativeScroll