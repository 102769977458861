// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/nette.ajax.history'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

//Scroll service
import ScrollService from './services/Scroll'
document.body.classList.add('is-explorer')
ScrollService.useNative()

const DEVELOPMENT = window.DEVELOPMENT || false

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { loadComponents, removeComponents } from './core/componentUtils'

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'


import Swup from 'swup'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'

function setScrollPosition() {
    const id = window.location.hash.replace('#', '')
    const anchor = document.getElementById(id)

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'force' })
    } else {
        ScrollService.setPosition(0, 0)
    }
}


/* if (!DEVELOPMENT) {
    const scrollStack = []

    function pushScrollPosition() {
        const location = window.location.href
        const offset = ScrollService.getScroll().y

        scrollStack.push({
            location,
            offset
        })

        if (scrollStack > 10) {
            scrollStack.shift()
        }
    }

    function popScrollPosition() {
        return scrollStack.pop()
    }

    let currentScroll = null
    const swup = new Swup({
        linkSelector: [
            'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="/"]:not([data-no-swup]):not([data-component]):not([target="_blank"]):not(.ajax)',
            'a[href^="#"]:not([data-no-swup]):not([data-component]):not(.ajax)'
        ].join(', '),
        containers: ['#page-content', '#modals', '#navigation', '#nav-layer'],
        animationSelector: '.Section',
        debugMode: false,
        doScrollingRightAway: false,
        animateScroll: true,
        preload: true,
        cache: true,
        plugins: [new SwupBodyClassPlugin()],
        skipPopStateHandling: event => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        }
    });

    swup.on('willReplaceContent', event => {
        const elements = queryAll('[data-swup]')
        queryAll('[data-swup]').forEach(element => removeComponents(element))
        EventBus.emit('swup:beforecontentchange')
    })

    swup.on('contentReplaced', event => {
        // Nette.reinit()
        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        queryAll('[data-swup]').forEach(element => loadComponents(element))

        if (currentScroll) {
            setTimeout(() => {
                ScrollService.setPosition(0, currentScroll.offset)
                currentScroll = null
            }, 0)
        } else if (id && anchor && !document.documentElement.classList.contains('to-category')) {
            const box = anchor.getBoundingClientRect()

            ScrollService.setPosition(0, box.top + ScrollService.getScroll().y)
        } else {
            ScrollService.setPosition(0, 0)
        }

        if ($.nette) {
            $.nette.load()
        }

        EventBus.emit('swup:aftercontentchange')
    })

    swup.on('transitionEnd', event => {
        EventBus.emit('swup:transitionend')
    })

    swup.on('popState', event => {
        const settings = popScrollPosition()
        if (settings?.location == window.location.href) {
            currentScroll = settings
        }
    })
} */


EventBus.on('nette:ajax:complete', () => {
    ScrollService.resize()
})

loadComponents().then(() => {
    setTimeout(() => {
        setScrollPosition()
    }, 500);
})
