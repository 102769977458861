import EventBus from '../../core/EventBus'

$.nette.ext('events', {
    before: () => {
        EventBus.emit('nette:ajax:start')
    },
    success: payload => {
        const { component } = payload
        if (component?.name) {
            EventBus.emit('nette:event', component)
        }
        EventBus.emit('nette:ajax:success')
    },
    complete: () => {
        EventBus.emit('nette:ajax:complete')
    }
})