import { triggerEvent } from '../../utils/dom'

var timer = null

export function submitForm(node, ajax = false) {
    if (!node) {
        return
    }

    const form = node.closest('form')

    if (ajax || form.classList.contains('ajax')) {
        $.nette.ajax({
            url: form.action,
            method: form.method || 'GET',
            dataType: 'json',
            data: $(form).serialize()
        });
    } else {
        form.submit()
    }
}

document.addEventListener('nette:autosubmit', event => {
    if (event.defaultPrevented) {
        return
    }

    submitForm(event.target)
})

document.addEventListener('change', event => {
    const form = event.target.closest('form')
    const field = event.target.closest('select[data-autosubmit], input[data-autosubmit]')

    if (field || form?.dataset?.autosubmit != null) {
        triggerEvent(form, 'nette:autosubmit')
    }
})

document.addEventListener('keyup', event => {
    const form = event.target.closest('form')
    const field = event.target.closest('input[data-autosubmit], textarea[data-autosubmit]')

    if (field || form?.dataset?.autosubmit != null) {
        clearTimeout(timer)
        timer = setTimeout(() => triggerEvent(form, 'nette:autosubmit'), 200)
    }
})